import axios from 'axios';
import {API_NEXT_VERSION, API_URL} from '../config/Api';
import {store} from './GeneralService';
import {gvauthSelToken} from '../reducers/GNVAuthReducer';
import { history } from './GeneralService';
import { ROUTES } from '../config/Routes';
import Cookies from 'js-cookie';
import { GNVIEW_EMAIL } from '../constants/LocalStorage';

class ApiService {
    makeApiCall = (endpoint, paramsObject = {}, method = 'post', acceptVersion = false) => {
        const userEmail = Cookies.get(GNVIEW_EMAIL);
        if (userEmail) {
            Cookies.set(GNVIEW_EMAIL, userEmail, { expires: 1 });
        }
        const apiUrl = API_URL + endpoint;
        const axiosCancelSource = axios.CancelToken.source();

        const axiosSettings = {
            method,
            url: apiUrl,
            ...(method === 'post' && {data: paramsObject}),
            ...(method === 'get' && {params: paramsObject}),
            ...(method === 'put' && {data: paramsObject}),
            timeout: 1000 * 60,
            headers: {
                Authorization: `Bearer ${gvauthSelToken(store.getState())}`,
                ...(acceptVersion && {'Accept-Version': API_NEXT_VERSION})
            },
            cancelToken: axiosCancelSource.token
        };

        return axios(axiosSettings)
            .then((response) => {
                if (response?.data && response?.headers && (response?.status === 200 || response?.status === 201)) {
                    return {result: response.data, headers: response.headers, cancelToken: axiosCancelSource};
                } else {
                    return new Error('API call error');
                }
            })
            .catch((error) => {
                let e = error;
                if (e?.response?.status === 401) {
                    localStorage.clear();
                    window.location.replace(ROUTES.LANDING);
                }
                if (e?.response?.status === 403 && e?.response?.headers?.location === ROUTES.AUTH_FORBIDDEN) {
                    history.push(ROUTES.AUTH_FORBIDDEN);
                }
                // try to create a readable error
                if (error?.response?.data?.error) {
                    e = error.response.data.error;
                }
                console.error("*** API CALL ERROR RESPONSE ***");
                console.error(e);

                throw e;
            });
    }
}

const apiService = new ApiService();

export default apiService;
